import { Skeleton } from "@/components/ui/skeleton"

export function WorkshopCollectionSkeleton() {
    return (
        <section className="py-8">
            <div className="container px-4">
                <div className="mb-6 flex items-center justify-between">
                    <Skeleton className="h-8 w-40" />
                    <div className="flex items-center gap-2">
                        <Skeleton className="h-9 w-24 hidden sm:block" />
                        <div className="flex">
                            <Skeleton className="h-9 w-9 rounded-r-none" />
                            <Skeleton className="h-9 w-9 rounded-l-none" />
                        </div>
                    </div>
                </div>

                <div className="flex gap-4 overflow-x-auto pb-4">
                    {Array.from({ length: 5 }).map((_, i) => (
                        <div key={i} className="flex-shrink-0">
                            <div className="relative shrink-0 animate-pulse rounded-md bg-gray-200 dark:bg-gray-900  w-[220px]">
                                <div className="overflow-hidden rounded-md bg-gray-100 dark:bg-gray-700 aspect-[3/4]"></div>
                                <div className="absolute bottom-0 left-0 right-0 p-3">
                                    <div className="h-4 w-3/4 rounded bg-gray-50 dark:bg-gray-800"></div>
                                    <div className="mt-2 h-3 w-1/2 rounded bg-gray-50/50 dark:bg-gray-800"></div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    )
}

